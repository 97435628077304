
import {defineComponent} from "vue";

import {Api} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import {dateformat, formatCurrency} from "@/core/filters/filters";

export default defineComponent({
  name: "EmployeePrintCard",
  setup() {
    return {
      dateformat,
      formatCurrency
    }
  },
  data() {
    return {
      employeeId: this.$route.params.id_employee,
      loaded: false,
      currentRoleLoaded: false,
      employee: {},
      currentRole: {}
    }
  },
  mounted: function () {
    this.getCurrentEmployee()
  },
  methods: {
    getCurrentEmployee() {
      ApiService.get(Api.GET_EMPLOYEE, {employee_id: this.employeeId}).then(response => {
        this.employee = response.data
        this.loaded = true;
        ApiService.get(Api.GET_CURRENT_EMPLOYEE_ROLE, {employee_id: this.employeeId})
          .then(response => {
            this.currentRole = response.data;
            this.currentRoleLoaded = true;
            this.printWindow();
          })
      })
    },
    printWindow: function () {
      window.print();
    }
  }
})
;
